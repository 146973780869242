// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as DashboardCss from "../../../../styleguide/dashboard/components/views/common/DashboardCss.res.js";
import * as DashboardTabs from "../../../../styleguide/dashboard/components/tabs/DashboardTabs.res.js";
import * as DashboardShowCss from "../../../../styleguide/dashboard/components/views/show/DashboardShowCss.res.js";
import * as DashboardTabBody from "../../../../styleguide/dashboard/components/tabs/components/DashboardTabBody.res.js";
import * as DashboardTabMenu from "../../../../styleguide/dashboard/components/tabs/components/DashboardTabMenu.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTabMenuItem from "../../../../styleguide/dashboard/components/tabs/components/DashboardTabMenuItem.res.js";
import * as DashboardSuspiciousIpsNavbar from "../common/navbar/DashboardSuspiciousIpsNavbar.res.js";
import * as DashboardSuspiciousIpEditEmails from "./components/DashboardSuspiciousIpEditEmails.res.js";
import * as DashboardSuspiciousIpEditStatus from "./components/DashboardSuspiciousIpEditStatus.res.js";

function reducer(_state, action) {
  return {
          currentTab: action._0
        };
}

var initialState = {
  currentTab: "Status"
};

function DashboardSuspiciousIpEdit$default(props) {
  var suspiciousIp = props.suspiciousIp;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.currentTab;
  var tmp;
  tmp = match$1 === "Status" ? "Selected" : "NotSelected";
  var match$2 = state.currentTab;
  var tmp$1;
  tmp$1 = match$2 === "Status" ? "NotSelected" : "Selected";
  var match$3 = state.currentTab;
  var tmp$2;
  tmp$2 = match$3 === "Status" ? JsxRuntime.jsx(DashboardSuspiciousIpEditStatus.make, {
          suspiciousIp: suspiciousIp
        }) : JsxRuntime.jsx(DashboardSuspiciousIpEditEmails.make, {
          suspiciousIp: suspiciousIp
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardSuspiciousIpsNavbar.make, {
                      currentLink: "Edit",
                      suspiciousIp: suspiciousIp
                    }),
                JsxRuntime.jsx(Container.make, {
                      className: DashboardCss.container,
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsxs("h1", {
                                                children: [
                                                  JsxRuntime.jsx("span", {
                                                        children: "Edit Suspicious IP Address",
                                                        className: DashboardShowCss.titleSpan
                                                      }),
                                                  suspiciousIp.ipAddress
                                                ],
                                                className: DashboardShowCss.title
                                              }),
                                          className: DashboardShowCss.heading
                                        }),
                                    className: DashboardShowCss.header
                                  }),
                              JsxRuntime.jsxs(DashboardTabs.make, {
                                    children: [
                                      JsxRuntime.jsxs(DashboardTabMenu.make, {
                                            children: [
                                              JsxRuntime.jsx(DashboardTabMenuItem.make, {
                                                    title: "Status",
                                                    tabType: tmp,
                                                    onClick: (function (param) {
                                                        dispatch({
                                                              TAG: "SetCurrentTab",
                                                              _0: "Status"
                                                            });
                                                      })
                                                  }),
                                              JsxRuntime.jsx(DashboardTabMenuItem.make, {
                                                    title: "Emails",
                                                    tabType: tmp$1,
                                                    onClick: (function (param) {
                                                        dispatch({
                                                              TAG: "SetCurrentTab",
                                                              _0: "Emails"
                                                            });
                                                      })
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsx(DashboardTabBody.make, {
                                            children: tmp$2
                                          })
                                    ]
                                  })
                            ],
                            className: DashboardCss.bodyContainer
                          })
                    })
              ]
            });
}

var Css;

var Tabs;

var TabMenu;

var TabMenuItem;

var TabBody;

var $$default = DashboardSuspiciousIpEdit$default;

export {
  Css ,
  Tabs ,
  TabMenu ,
  TabMenuItem ,
  TabBody ,
  reducer ,
  initialState ,
  $$default as default,
}
/* react Not a pure module */
